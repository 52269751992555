import Link from '@mui/material/Link'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXTwitter, faBluesky } from '@fortawesome/free-brands-svg-icons'
import { faAt } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
    return (
      <div className="flex flex-col items-center bg-gray-200 p-10 text-gray-900">
        <div className="flex flex-col items-center">
          <Link href="https://x.com/PrecoSupremo" rel="nofollow" className="flex p-1" underline="none" color="#1976D2">
            <div className="flex items-center ">
              <FontAwesomeIcon icon={faXTwitter} />
              <span className="px-2">x</span>
            </div>
          </Link>
          <Link href="https://bsky.app/profile/precosupremo.com.br" rel="nofollow" className="flex p-1" underline="none" color="#1976D2">
            <div className="flex items-center">
              <FontAwesomeIcon icon={faBluesky} />
              <span className="px-2">bluesky</span>
            </div>
          </Link>
          <Link href="mailto:contato@precosupremo.com.br" rel="nofollow" className="flex p-1" underline="none" color="#1976D2">
            <div className="flex items-center">
              <FontAwesomeIcon icon={faAt}/>
              <span className="px-2">email</span>
            </div>
          </Link>
        </div>
        <div className="py-4"></div>
        <div className="flex flex-col items-center text-sm">
          <p>Copyright © 2024 Preço Supremo</p>
          <p>All Rights Reserved</p>
        </div>
      </div>
    )
  }
  
  export default Footer
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import Link from '@mui/material/Link'

import { useNavigate } from "react-router-dom"
import { faCircleDown } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const SingleProduct = ({ product }) => {
    const { title, productModelId, image, specificationId, category, store, storeLink } = product

    const price = Number(product.price)
    const minimumPrice = Number(product.minimumPrice)

    const navigate = useNavigate()

    const onClickCompare = () => navigate(`/products/category/${category}/specs?productModelId=${productModelId}&specificationId=${specificationId}`)

    const minPrice = () => {
        if (price <= minimumPrice)  {
          return (
            <Tooltip title="Menor preço histórico!">
              <IconButton aria-label="low-price" sx={{ padding: "2px" }}>
                <FontAwesomeIcon icon={faCircleDown} size="sm" style={{"color": "#008000",}} />
              </IconButton>
            </Tooltip>
          )
        }
        else if (price <= (minimumPrice * 1.05)) {
          return (
            <Tooltip title="Ótimo preço!">
              <IconButton aria-label="low-price" sx={{ padding: "2px"}}>
                <FontAwesomeIcon icon={faCircleDown} size="sm" style={{"color": "#888800",}} />
              </IconButton>
            </Tooltip>
          )
        }
        else if (price <= (minimumPrice * 1.10)) {
          return (
            <Tooltip title="Bom preço!">
              <IconButton aria-label="low-price" sx={{ padding: "2px"}}>
                <FontAwesomeIcon icon={faCircleDown} size="sm" style={{"color": "#008888",}} />
              </IconButton>
            </Tooltip>
          )
        }
        else {
          return ""
        }
    }

    return (
      <div className="flex items-center max-w-[270px] flex-col bg-gray-50 gap-3 shadow-md hover:shadow-xl hover:scale-110 hover:border-solid border-2 border-transparent hover:border-sky-700 duration-300 hover:z-10 px-4 py-7 rounded-sm overflow-hidden dark:bg-[#404040]">
        <h2 className="flex justify-center text-stone-950 font-sans text-pretty text-xs h-10 dark:text-white cursor-pointer text-left" onClick={onClickCompare}>
          {title}
        </h2>
        <div className="flex justify-center relative">
          <img
            className="w-48 h-48 object-contain cursor-pointer"
            src={image}
            alt={title}
            onClick={onClickCompare}
          />
          <div className="rounded-lg absolute self-start right-0 flex pl-3 label-border">
            { minPrice() }
          </div> 
        </div>
        <div className="flex flex-col self-stretch gap-0.5 px-1">
          <div className="flex justify-between text-sm text-gray-600 gap-5 dark:text-white">
            <div className="flex">
              <Link className="flex self-center text-xs text-left " href={storeLink} rel="sponsored" target="_blank" underline="none">
                {store}
              </Link>
            </div>
            <span className="text-green-600 font-semibold text-xl">R$ {price.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</span>
          </div>
        </div>
      </div>
    )
}

export default SingleProduct